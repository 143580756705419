var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "2/3", pageTitle: _vm.pageTitle } },
    [
      _c(
        "div",
        { staticClass: "vx-col w-full mb-base" },
        [
          _c(
            "vx-card",
            [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-2/3" },
                  [
                    _c("travelify-display-field", {
                      attrs: {
                        label: "Credit Limit",
                        value: _vm.formattedAmount,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-2/3" },
                  [
                    _c("travelify-display-field", {
                      attrs: {
                        label: "Credit Remaining",
                        value: _vm.formattedcreditRemaining,
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("ag-grid-vue", {
                ref: "agGridTable",
                staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
                attrs: {
                  components: _vm.cellRendererComponents,
                  columnDefs: _vm.columnDefs,
                  defaultColDef: _vm.defaultColDef,
                  rowData: _vm.creditUsages,
                  gridOptions: _vm.gridOptions,
                  rowSelection: "single",
                  pagination: true,
                  paginationPageSize: _vm.paginationPageSize,
                  suppressPaginationPanel: true,
                },
                on: { "grid-size-changed": _vm.onGridSizeChanged },
              }),
              _c(
                "div",
                { staticClass: "flex flex-wrap justify-end" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4 sm:mb-0 mb-2",
                      attrs: { type: "border" },
                      on: { click: _vm.manageCreditLimit },
                    },
                    [
                      _vm._v(
                        "\n          Back to Manage Credit Limit\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }